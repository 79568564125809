import { useEffect, useState } from "preact/hooks";

interface Position {
  x: number;
  y: number;
}

interface UnitPlacement {
  UnitName: string;
  Position: Position;
}

interface Level {
  units: UnitPlacement[];
}

interface HexGridEditorProps {
    database: string;
    levelNumber: number;
    onLevelsChange: () => void;  // Add this prop
  }

export default function HexGridEditor(
  { database, levelNumber, onLevelsChange }: HexGridEditorProps,
) {
  const [level, setLevel] = useState<Level>({ units: [] });
  const [availableUnits, setAvailableUnits] = useState<string[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>("");
  const [existingLevels, setExistingLevels] = useState<number[]>([]);
  const [error, setError] = useState("");
  const [isNewLevel, setIsNewLevel] = useState(false);

  // Constants for hex grid
  const ROWS = 6;
  const COLS = 6;
  const HEX_SIZE = 40;
  const WIDTH = Math.sqrt(3) * HEX_SIZE;
  const HEIGHT = 2 * HEX_SIZE;
  const SPACING = 2;

  useEffect(() => {
    loadData();
  }, [database, levelNumber]);

  const loadData = async () => {
    try {
      const response = await fetch(
        `/api/levelEditor?database=${
          encodeURIComponent(database)
        }&levelNumber=${levelNumber}`,
      );
      if (!response.ok) throw new Error("Failed to load data");

      const data = await response.json();
      setAvailableUnits(data.unitNames);
      setSelectedUnit(data.unitNames[0] || "");
      setLevel(data.level);
      setExistingLevels(data.existingLevels);
      setIsNewLevel(!data.existingLevels.includes(levelNumber));
      setError("");
    } catch (err) {
      console.error(err);
      setError("Failed to load data");
    }
  };

  const saveLevel = async () => {
    try {
      const basePath = database.split('/carddatabase')[0];
      const response = await fetch('/api/level', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          database: basePath,
          levelNumber,
          level
        }),
      });

      if (!response.ok) throw new Error('Failed to save level');
      
      // After successful save, reload the levels list
      onLevelsChange();
      setError("");
    } catch (err) {
      setError("Failed to save level");
    }
  };

  const deleteLevel = async () => {
    if (!confirm(`Are you sure you want to delete Level ${levelNumber}?`)) {
      return;
    }

    try {
      const basePath = database.split('/carddatabase')[0];
      const response = await fetch('/api/level', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          database: basePath,
          levelNumber
        }),
      });

      if (!response.ok) throw new Error('Failed to delete level');
      
      // After successful delete, reload the levels list
      onLevelsChange();
      setError("");
    } catch (err) {
      setError("Failed to delete level");
    }
  };


  const getHexPoints = (centerX: number, centerY: number): string => {
    const points = [];
    for (let i = 0; i < 6; i++) {
      const angle = (Math.PI / 3) * i + Math.PI / 6;
      const x = centerX + HEX_SIZE * Math.cos(angle);
      const y = centerY + HEX_SIZE * Math.sin(angle);
      points.push(`${x},${y}`);
    }
    return points.join(" ");
  };

  const getHexPosition = (col: number, row: number) => {
    const yOffset = HEIGHT * 0.75;
    const xOffset = WIDTH;
    let x = col * xOffset;
    let y = row * yOffset;

    if (row % 2 === 1) {
      x += xOffset * 0.5;
    }

    return { x, y };
  };

  const getUnitAtPosition = (x: number, y: number) => {
    return level.units.find((unit) =>
      unit.Position.x === x && unit.Position.y === y
    );
  };

  const handleHexClick = (col: number, row: number) => {
    if (!selectedUnit) return;

    const existingUnit = getUnitAtPosition(col, row);
    if (existingUnit) {
      // Remove unit if it exists
      setLevel({
        ...level,
        units: level.units.filter((u) =>
          !(u.Position.x === col && u.Position.y === row)
        ),
      });
    } else {
      // Add new unit
      setLevel({
        ...level,
        units: [...level.units, {
          UnitName: selectedUnit,
          Position: { x: col, y: row },
        }],
      });
    }
  };

  return (
    <div class="p-4">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-bold">
          {isNewLevel ? "New Level " : "Level "}
          {levelNumber} Editor
        </h2>
        {isNewLevel && (
          <span class="bg-yellow-100 text-yellow-800 px-2 py-1 rounded text-sm">
            Unsaved Level
          </span>
        )}
      </div>

      <div class="mb-4">
        <h3 class="text-sm font-medium text-gray-700 mb-1">Existing Levels:</h3>
        <div class="flex flex-wrap gap-2">
          {existingLevels.map((num) => (
            <span
              key={num}
              class="px-2 py-1 bg-blue-100 text-blue-800 rounded text-sm"
            >
              Level {num}
            </span>
          ))}
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          Select Unit to Place
        </label>
        <select
          class="w-full p-2 border rounded"
          value={selectedUnit}
          onChange={(e) => setSelectedUnit(e.currentTarget.value)}
        >
          {availableUnits.map((unit) => (
            <option key={unit} value={unit}>{unit}</option>
          ))}
        </select>
      </div>

      <div class="mb-4 overflow-auto">
        <svg
          width={WIDTH * (COLS + 1)}
          height={HEIGHT * (ROWS * 0.75 + 0.25)}
          viewBox={`0 0 ${WIDTH * (COLS + 1)} ${HEIGHT * (ROWS * 0.75 + 0.25)}`}
          class="border rounded"
        >
          {Array.from({ length: ROWS }, (_, row) =>
            Array.from({ length: COLS + (ROWS % 2 == 1 ? 1 : 0) }, (_, col) => {
              const pos = getHexPosition(col, row);
              const unit = getUnitAtPosition(col, row);
              return (
                <g
                  key={`${col}-${row}`}
                  onClick={() => handleHexClick(col, row)}
                >
                  <polygon
                    points={getHexPoints(pos.x + WIDTH / 2, pos.y + HEIGHT / 2)}
                    fill={unit ? "#e5e7eb" : "white"}
                    stroke="#4b5563"
                    strokeWidth="2"
                    class="cursor-pointer hover:fill-gray-200"
                  />
                  {unit && (
                    <text
                      x={pos.x + WIDTH / 2}
                      y={pos.y + HEIGHT / 2}
                      text-anchor="middle"
                      dominant-baseline="middle"
                      class="text-xs pointer-events-none"
                    >
                      {unit.UnitName}
                    </text>
                  )}
                </g>
              );
            }))}
        </svg>
      </div>

      {error && (
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div class="flex justify-between mt-4">
        <button
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={saveLevel}
        >
          {isNewLevel ? "Create Level" : "Save Changes"}
        </button>

        {!isNewLevel && (
          <button
            class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            onClick={deleteLevel}
          >
            Delete Level
          </button>
        )}
      </div>
    </div>
  );
}
